<template>
  <v-container>
    <v-card class="elevation-2 mx-auto" :loading="loading" max-width="600">
      <v-card-title>Edit Pricing</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <form>
          <v-row>
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-text-field
                label="Title"
                v-model="form_data.title"
                :error-messages="form_error.title"
                outlined
                dense
                autofocus
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-text-field
                label="Price"
                v-model="form_data.price"
                :error-messages="form_error.price"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="py-0">
              <p class="font-weight-bold mt-2">Content</p>
              <vue-editor v-model="form_data.content" :editorToolbar="customToolbar">
              </vue-editor>
              <span v-if="form_error.content" :style="{ color: 'red' }">{{ form_error.content }}</span>
            </v-col>
          </v-row>
          <v-btn
            class="mr-4 mt-6"
            color="secondary"
            @click="upadtePricing"
            :loading="loading"
            :disabled="loading"
          >Update</v-btn>
        </form>
      </v-card-text>
    </v-card>
    <v-btn small color="secondary" fixed fab bottom left to="/board">
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
  </v-container>
</template>
<script>
import { VueEditor } from "vue2-editor";
import { mapMutations } from "vuex";

export default {
  name: "EditObject",
  components: {
    VueEditor,
  },
  data() {
    return {
      form_data: {},
      form_error: {},
      loading: false,
      data: {},
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["link"],
      ],
    };
  },
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    getPricingDetails() {
      this.loading = true;
      let _self = this;      
      this.$axios.get(`admin/price/details/${this.$route.params.id}`)
        .then((res) => {
          _self.form_error = {};
          if (res.status) {
            let data = res.data.data;
            for (let key in data) {
              _self.form_data[key] = data[key];
              var doc = new DOMParser().parseFromString(
                _self.form_data.content,
                "text/html"
              );
              _self.form_data.content = doc.documentElement.innerHTML;
              console.log("unescaped html",_self.form_data.content);
            }
          } else {
            let errors = res.error;
            for (let key in errors) {
              _self.form_error[errors[key].field] = errors[key].message;
            }
          }
          console.log(_self.form_data);
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
    upadtePricing() {
      let _self = this;
      this.loading = true;

      let serverData = JSON.parse(JSON.stringify(this._data.form_data));
      let formData = new FormData();
      for (let key in serverData) {
        formData.append(key, serverData[key]);
      }
      
      this.$axios.put(`admin/price/edit/${this.$route.params.id}`, formData)
        .then((response) => {
          _self.form_error = {};
          let response_data = response.data;
          if (response_data.status) {
            _self.setAlert({
              show: true,
              variant: "success",
              message: response.data.message,
              dismissCountDown: 5000,
            });
            _self.$router.push("/pricing");
          } else {
            let errors = response_data.error;
            for (let key in errors) {
              _self.form_error[errors[key].field] = errors[key].message;
            }
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
  },
  mounted: function () {
    this.getPricingDetails();    
  },
};
</script>